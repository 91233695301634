$azul-claro: #59C9F3;
$azul-medio: #0066B2;
$azul-escuro: #072760;
$laranja: #CC6200;
$amarelo: #FFB800;

$branco: #fff;
$preto: #000;
$meio-preto: #232323;

$cinza:#E8EAF3;
$cinza2: #ADAFBC;
$cinza3:#b8b8b8;
$cinza4: #9F9F9F;

$cinza-claro: #CFDBEC;
$cinza-escuro: #CBCBCB;
$cinza-preto: #666666;

$roxo: #5E358C;

.texto{
    background-color: rgb(49, 49, 49);

}

.case-card
{
    transition: all 0.8s;
    height: 20rem;
    color: white;

    .case-txt-1
    {
        // opacity: 1;
        position: relative;
        top: 5rem;
        transition: all 0.8s;
    }

    .case-txt-2
    {
        opacity: 0;
        transition: all 0.8s;
    }
    .case-fundo
    {
        background-color: transparent;
        width: 100%;
        height: 100%;
        transition: all 0.8s;
    }
}

.case-card:hover
{
    .case-txt-1
    {
        // opacity: 0;
        top: -10rem;
    }
    .case-txt-2
    {
        opacity: 1;
    }
    .case-fundo
    {
        background-color: #80008080;
    }
}