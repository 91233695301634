// Não se esqueça de recompilar os estilos para poder ver as alterações feitas aqui!
//
// Você pode fazer isso pelo terminal de duas maneiras:
//
// npm run dev
// *Irá recompilar uma única vez todos os estilos e scripts do projeto*
//
// npm run watch-poll
// *Irá recompilar todos os estilos e scripts do projeto a primeira vez, e depois continuará rodando de fundo, e cada vez que você alterar um arquivo CSS ou JS e salvar,
//  ele irá compilar apenas o que você alterou!*
@font-face {
    font-family: "montserrat";
    src: url("/fonts/montserrat/Montserrat-Regular.ttf") format("ttf");
    src: url("/fonts/montserrat/Montserrat-Bold.ttf") format("ttf");
    src: url("/fonts/montserrat/Montserrat-ExtraBold.ttf") format("ttf");
    src: url("/fonts/montserrat/Montserrat-Medium.ttf") format("ttf");
    src: url("/fonts/montserrat/Montserrat-Light.ttf") format("ttf");
}

@font-face {
    font-family: "montserrat";
    src: url("/fonts/Montserrat/Montserrat-Regular.ttf");
}

@font-face {
    font-family: "montserrat";
    src: url("/fonts/Montserrat/Montserrat-Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "montserrat";
    src: url("/fonts/Montserrat/Montserrat-ExtraBold.ttf");
    font-weight: bolder;
}

@font-face {
    font-family: "montserrat";
    src: url("/fonts/Montserrat/Montserrat-Medium.ttf");
    font-weight: 400;
}

@import "cores";
.img-func {
    height: 250px;
    width: 100%;
    background-size: cover;
    border-radius: 15px 15px 0 0px;
}

#comentariosCarousel {
    .card {
        background-color: #fff;
        border: none;
        border-radius: 20px;
        min-height: 100%;
        height: auto;
        .aluno {
            position: absolute;
            bottom: 30px;
            padding: 0 2rem;
            .nome {
                margin-top: 0.5rem;
            }
            .aspas {
                margin-top: 0.5rem;
            }
        }
        .rounded-circle {
            // margin:40px auto;
            position: absolute;
            width: 5rem;
            height: 4.5rem;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 19px;
            line-height: 22px;
            color: $preto;
            font-family: montserrat;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 21px;
            color: $preto;
            font-family: montserrat;
        }
        span {
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 16px;
            color: #696969;
            font-family: montserrat;
        }
    }
}

// @media screen and (min-width: 992px) and (max-width: 1240px) {
//     .img-func {
//         width: 25vw;
//     }
// }
.marker {
    background-color: rgba(255, 255, 0, 0.692);
}

html {
    scroll-behavior: smooth;
}


/*--------------------------------------------------------------
# menu
--------------------------------------------------------------*/

@media (prefers-reduced-motion: no-preference) {
     :root {
        scroll-behavior: auto;
    }
}

.fix-header {
    box-shadow: 0px 8px 28px 0px rgba(176, 176, 176, 1);
}

.whatsapp {
    width: 65px;
    right: 30px;
    height: auto;
    bottom: 30px;
    z-index: 999;
    position: fixed;
    filter: drop-shadow(0 0 2px #333);
    transition: all 0.7s;
}

.whatsapp:hover {
    transform: rotate(15deg);
    filter: drop-shadow(0 0 5px #333);
    /* transform: scale(1.1); */
}


/*--------------------------------------------------------------
  # Desabilita o delay do AOS em mobile
  --------------------------------------------------------------*/

@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}


/*--------------------------------------------------------------
  # menu
  --------------------------------------------------------------*/

// .fix-header {
//     position: relative;
//     top: 2rem;
// }
.menu1 {
    max-width: 80%;
    // position: relative;
    // top: -1rem;
}

.menu1 .info-icons {
    font-size: 30px;
    color: $azul-claro;
    border-left: 1px solid #afafaf;
}

.menu-cinza {
    background-color: #e8e9ed;
    .social {
        color: $cinza-preto;
    }
    .contato {
        color: $azul-medio;
        position: relative;
        top: 3px;
    }
}

.botao-menu {
    padding: 15px 30px;
    background-color: $azul-claro;
    border-radius: 10px;
    transition: all 0.9s;
    font-family: montserrat;
    color: #072760;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    font-weight: 100;
    font-size: 1rem;
    line-height: 24px;
}

.botao-menu:hover {
    color: $branco;
    font-weight: 600;
    background-color: $azul-medio;
    transition: all 0.9s;
}

.dropdown-menu--animate.collapsing {
    display: block;
}

.dropdown-menu--animate {
    opacity: 0.8;
    height: 0;
    display: block;
    transition: all 0.3s;
    overflow: hidden;
    transform-origin: top;
    transform: scale(1, 0);
}

.dropdown-menu--animate.show {
    opacity: 1;
    height: auto;
    transition: all 0.3s;
    transform: scale(1);
}

.navbar {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background-color: transparent;
    z-index: 1001;
    transition: all 0.8s;
}

.navbar .nav-link {
    font-family: montserrat;
    font-size: 1rem;
    color: #000;
    font-weight: 300;
}

.navbar .nav-link:hover {
    font-weight: 900;
    color: #000;
}

@media (max-width: 767.98px) {
    .navbar {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}

.navbar-brand {
    display: contents;
}

.navbar-brand .logo1 {
    position: relative;
    width: 190px;
    transition: all 0.7s;
    left: 5rem;
}

.navbar-brand .logo1:hover {
    transform: scale(1.1);
}

.nav-link {
    color: #000;
    transition: all 0.5s;
}

.nav-link:hover {
    color: #000;
}

.drp-icon {
    font-size: 0.8em !important;
}

@media screen and (max-width: 1091px) {
    .nav-link {
        font-size: 0.8em;
    }
}

.nav-link i {
    font-size: 2.8em;
    position: relative;
    /* top: 3px; */
}

.dropdown-toggle::after {
    vertical-align: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}

.fix-header {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    z-index: 900;
    /* background-color: transparent; */
    -webkit-transition: All 1s ease;
    -moz-transition: All 1s ease;
    -o-transition: All 1s ease;
    transition: All 1s ease;
    // position: static;
}

.fix-header .fix-men {
    position: fixed;
    z-index: 1029;
    width: 100%;
    top: -313px;
    transition: all 0.7s;
}

.fix-header .fixed-menu {
    padding: 10px 0;
    position: fixed;
    top: 0;
    margin-top: 0px;
    width: 100%;
}

.fix-header .active {
    font-weight: 900;
    color: #000;
}

.fixed-menu {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(85, 85, 85, 1);
    -moz-box-shadow: 0px 0px 20px 1px rgba(85, 85, 85, 1);
    box-shadow: 0px 0px 20px 1px rgba(85, 85, 85, 1);
    /*background-color:rgba(255,255,255,0.9);*/
    position: fixed;
    border: none;
    opacity: 1;
    a {
        color: black !important;
    }
}

.fixed-menu .navbar {
    padding: 0;
}

.fixed-menu h1 {
    top: 0;
}

.fixed-menu img {
    width: 200px;
}

.bg-white {
    background-color: #fff;
}

.bg-white .nav-link {
    color: #333;
    transition: all 0.7s;
}

.bg-invi {
    background-color: #26231d;
}

.bg-invi .nav-link {
    color: #fff;
}

.fixed-menu .bg-invi {
    background-color: #26231d !important;
}

.fixed-menu .bg-invi .nav-link {
    color: #fff;
}

.bx-menu {
    color: #000;
}

.navbar-toggler:focus {
    box-shadow: 0 0 0 0.15rem;
}

// .navbar-toggler-icon {
//     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
//   }
@media (max-width: 1399px) {
    #comentariosCarousel .card {
        background-color: #fff;
        border: none;
        border-radius: 20px;
        //height: 500px;
        min-height: 100%;
        height: auto;
    }
    .img-func {
        height: 250px;
        width: 100%;
    }
}

@media (max-width: 1199px) {
    .img-func {
        height: 250px;
        width: 305px;
    }
    .nav-item .px-3 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .navbar .nav-link {
        font-size: 0.8rem;
    }
    .botao-menu {
        font-size: 0.8rem;
    }
}

@media (max-width: 991px) {
    .menu1 {
        max-width: 100%;
    }
    #comentariosCarousel {
        .rounded-circle {
            position: relative!important;
        }
    }
}

@media (max-width: 767px) {}

@media (max-width: 575px) {
    #comentariosCarousel .card {
        background-color: #fff;
        border: none;
        border-radius: 20px;
        min-height: 100%;
        height: auto;
    }
}

@media screen and (max-width: 991px) {
    .navbar-brand .logo1 {
        width: 150px;
    }
    .fixed-menu .logo1 {
        width: 70px;
    }
}

@media screen and (max-width: 767px) {
    .fixed-menu {
        padding: 5px 0 0 0;
    }
    .navbar-brand h1 {
        top: 0px;
    }
    .dropdown-menu {
        padding: 0;
        margin: 0;
    }
}


/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/

#footer-info {
    background-color: #242424;
}

#footer-info img {
    padding: 2rem 0;
    max-width: 10rem;
}

#footer-info p {
    color: $cinza3;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-family: montserrat;
}

#footer-info a {
    color: $cinza3;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-family: montserrat;
    text-decoration: none;
}

#footer-info h2 {
    font-family: montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    color: $branco;
}

#footer-info .texto2 {
    padding-top: 3rem;
}

#footer-info .texto3 {
    padding-top: 2.3rem;
}

.footerhr {
    background-color: #444444;
    width: 100%;
    margin: 0 auto;
    opacity: 1;
    height: 0.5px;
}

#footer-info .icones {
    max-width: 4rem;
    color: $cinza4;
}


/*--------------------------------------------------------------
  # Copyright bottom
  --------------------------------------------------------------*/

#copyright {
    background: #242424;
}

#copyright p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $cinza3;
    font-family: montserrat;
}

#copyright a {
    display: contents;
    color: $cinza3;
    font-family: montserrat;
}

#copyright img {
    width: 64px;
}

#copyright .poli {
    transition: all 0.5s;
}

#copyright .poli:hover {
    text-decoration: underline;
}

@media (max-width: 1399.98px) {}

@media (max-width: 1199.98px) {
    #footer-info h3 {
        padding: 0px;
    }
}

@media (max-width: 991.98px) {
    #footer-info h3 {
        padding: 0px;
        margin-top: 0px;
    }
}

@media (max-width: 980px) {}

@media (max-width: 767.98px) {}

@media (max-width: 575.98px) {
    #footer-info h3 {
        padding: 0px;
        margin-top: 0px;
    }
}

@media (max-width: 370px) {
    #footer-info h3 {
        padding: 0px;
        margin-top: 0px;
    }
}